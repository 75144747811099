@use './config.scss' as *;
@use './typography';

// Remove on live code
// *,
// * + *,
// * + * + * {
//   outline: 1px solid red;
// }

body {
  background-color: #eeeeee;
}

#acl {
  margin: 0;
  padding-inline: 0.5rem;
  // width: 100%;
  max-width: 60rem;
  margin-inline: auto;
  height: 100%;
  // font-size: 16px;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  padding-block: 1rem;
  font-weight: $normal;
  position: relative;

  .standout {
    color: var(--standout) !important;
  }

  @include breakpoint(laptop) {
    padding-block: 2rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  .tagline {
    color: $primary--200;
  }

  a:not(.button) {
    text-decoration: none;
    color: $primary--200;
    font-weight: 700;
  }

  >svg {
    margin: 0 auto;
  }

  * {
    box-sizing: border-box;
  }

  picture {
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    object-fit: cover;

    &[class*='ratio--'] {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }

    &.ratio--16by9 {
      aspect-ratio: 16 / 9;
    }

    &.ratio--4by3 {
      aspect-ratio: 4 / 3;
    }
  }

  // Margin

  .mt-32 {
    margin-top: 2rem;
  }

  // Flow
  .flow--4>*+* {
    margin-top: 0.25rem;
  }

  .flow--8>*+* {
    margin-top: 0.5rem;
  }

  .flow--12>*+* {
    margin-top: 0.75rem;
  }

  .flow--16>*+* {
    margin-top: 1rem;
  }

  .flow--32>*+* {
    margin-top: 2rem;
  }

  // Colours
  .primary--50 {
    color: $primary--50;
  }

  .primary--100 {
    color: $primary--100;
  }

  .primary--200 {
    color: $primary--200;
  }

  .primary--300 {
    color: $primary--300;
  }

  .neutral--50 {
    color: $neutral--50;
  }

  .neutral--100 {
    color: $neutral--100;
  }

  .neutral--200 {
    color: $neutral--200;
  }

  .neutral--300 {
    color: $neutral--300;
  }

  .neutral--400 {
    color: $neutral--400;
  }

  // Sizes
  .size--3 {
    font-size: $size--3;
  }

  // Columns
  .auto__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    gap: 1rem;
  }

  // Temp Highlight for testing
  .red {
    color: red;
  }
}

#acl>*+* {
  margin-top: 2rem;
}

#acl {
  z-index: 10;
}
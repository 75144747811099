// Additional style rules for EditCustomer component
.editCustomerDateOfBirth {
    display: flex;
    flex-direction: column;

    & input {
        height: 2.5rem;
        font-size: 1.25rem;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
        border: none;
        border-radius: 5px;
        text-align: center;
    }

    & label {
        padding-bottom: 5px;
        font-size: 1.5rem;
    }
}

.editCustomerPostCodeSearch {
    &Container {
        display: flex;
        flex-direction: column;

        & label {
            padding-bottom: 5px;
            font-size: 1.5rem;
        }
    }

    &Controls {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;

        & input {
            width: 100%;
            height: 2.5rem;
            font-size: 1.25rem;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
            border: none;
            border-radius: 5px;
            margin-right: 5px;
            text-align: center;
        }

        & button {
            padding: 0.5rem 1.5rem !important;
        }
    }

    &Results {
        display: flex;

        & select {
            width: 100%;
            height: 2.5rem;
            flex: 1;
            text-align: center;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
            border: none;
            border-radius: 5px;
        }
    }
}

// .errorList {
//     text-align: center;
// }
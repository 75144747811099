@use './config.scss' as *;

#acl {
  .h1 {
    font-size: $size--8;
  }

  .h2 {
    font-size: $size--7;
  }

  .h3 {
    font-size: $size--6;
  }

  .h4 {
    font-size: $size--5;

    &.stacked {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: .2rem;
    }
  }

  .h5 {
    font-size: $size--4;
  }

  .caption {
    font-size: $size--2;
  }

  .label {
    font-size: $size--1;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .label {
    line-height: $flat;
  }

  .h5,
  .caption {
    line-height: $tight;
  }

  p {
    font-size: $size--3;
    line-height: $loose;
  }

  .button > * {
    font-size: $size--3;
    font-weight: $semi;
    line-height: $flat;
  }

  .semi {
    font-weight: $semi;
  }

  .bold {
    font-weight: $bold;
  }

  .loose {
    line-height: $loose;
  }
  .tight {
    line-height: $tight;
  }
  .flat {
    line-height: $flat;
  }

  .card__data {
    .title {
      @extend .bold;
      font-size: clamp(3rem, 2.7183rem + 1.4085vw, 3.43rem);
      line-height: 1;
    }
  }
}

@use '../config.scss' as *;

.checks {
  display: flex;
  align-items: center;
}

.checkboxNative {
  min-height: 2rem;
  min-width: 2rem;
  margin-right: 0.5rem;
  border-radius: 15px;
}

.checkboxNative+label {
  cursor: pointer;
  color: $neutral--400;
}

.checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.checkbox+label {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 1.375rem;
  color: $neutral--400;
}

.checkbox+label::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: $neutral--200;
  border-radius: 0.25rem;
  box-shadow: none;
}

.checkbox:checked+label::after {
  content: ' ';
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+');
  background-repeat: no-repeat;
  background-size: 0.625rem 0.625rem;
  background-position: center center;
  border-radius: 0.25rem;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  left: 0px;
  top: 0px;
  text-align: center;
  font-size: 0.625rem;
  height: 1.375rem;
  width: 1.375rem;
  background-color: $primary--200;
  border-color: $primary--300;
}
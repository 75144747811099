// Additional style rules for EditVehicle component

.editVehicleRegistration input {
    background-color: #FFCF35;
    text-align: center;
    font-weight: bold;
}

// .editVehicleMake input {}

// .editVehicleModel input {}

// .editVehicleValue input {}
@use '../config.scss' as *;

#acl {
  .section {
    padding: 2rem;
    border-radius: $radius;

    @include breakpoint(smmobile) {
      padding: 1.125rem;
    }

    @include breakpoint(tablet) {
      padding-block: 4rem;
      padding-inline: 3.875rem;
    }

    &.section--shaded {
      background-color: $primary--50;
    }

    &.section--light {
      background-color: inherit;
    }
  }
}

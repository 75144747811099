// Base styling for input components in src/components/checkout
// inputDate, inputDropdown, inputNumber, inputSortCode, inputText

// Styles for date component are in the relevent stylesheet for the page they appear on, as they vary depending on the page

.input {

    &--text,
    &--number,
    &--sortCode,
    &--dropdown,
    // &--date,
    &--dateEditFinancing,
    &--dropdownEditFinancing,
    &--dateOfBirth {
        display: flex;
        flex-direction: column;

        &--EditRegistrationNumber {
            display: inherit;
        }
    }

    &--text input,
    &--number input,
    &--sortCode input,
    &--dropdown select,
    &--dateEditFinancing input,
    &--dropdownEditFinancing select,
    &--dateOfBirth input {
        height: 2.5rem;
        font-size: 1.25rem;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
        border: none;
        border-radius: 5px;
        text-align: center;
        outline: 0;
    }

    &--checkbox input {
        width: 2rem;
        height: 2rem;
        margin: 0;
        margin-top: 0.5rem;
    }

    &--text,
    &--number,
    &--sortCode,
    &--dropdown,
    &--checkbox,
    // &--date,
    &--dateEditFinancing,
    &--dropdownEditFinancing,
    &--dateOfBirth {
        label {
            padding-bottom: 0.5rem;
            font-size: 1.5rem;
        }
    }

    %errorCommon {
        color: greenyellow;
        margin: 5px 0 0 5px;

        display: block;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height 1s ease, opacity 1s ease;
    }

    &--textError,
    &--numberError,
    &--sortCodeError,
    &--dateError,
    &--dropdownError,
    &--inputDateOfBirthError {
        color: greenyellow;
        margin: 0.5rem 0 0 0;

        display: block;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height 0.5s ease, opacity 0.5s ease;

        &.visible {
            color: red;
            //max-height: 2rem;
            max-height: 300px;
            opacity: 1;
        }
    }
}

.badInput {
    border: 2px solid red;
    outline: 0;
}

.goodInput:focus {
    outline: 0;
    border: 2px solid greenyellow;
}
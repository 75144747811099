@use '../config.scss' as *;

#acl {
  .eligibility {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @include breakpoint(tablet) {
      grid-template-columns: 1fr 10px 1fr;
    }

    &__wrapper {
      .h4 {
        margin-block-end: 1rem;
      }
    }

    &__divider {
      width: 1px;
      height: auto;
      background-color: $neutral--100;
      justify-self: center;
      display: none;

      @include breakpoint(tablet) {
        display: block;
      }
    }
  }
}

@use '../../config.scss' as *;
@use '../../typography';

#acl {
  .callout {
    background: $primary--50;
    border-radius: $radius;
    padding-block: 0rem 2rem;
    padding-inline: 1rem;
    position: relative;
    z-index: 0;
    margin-top: 5rem;

    @include breakpoint(tablet) {
      padding-block: 0rem 4rem;
      padding-inline: 3.875rem;
    }

    &__thumbnail {
      position: relative;
      border-radius: $radius;
      overflow: hidden;
      z-index: 2;
      top: -2rem;
      margin-bottom: -2rem;
    }

    &__cover {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @include breakpoint(tablet) {
        flex-direction: row;
      }

      &__item {
        position: relative;
        padding-inline-end: 1rem;

        &:before {
          content: '';
          display: block;
          width: 90%;
          height: 1px;
          background: $neutral--100;
          position: absolute;
          left: 0rem;
          top: -1rem;

          @include breakpoint(tablet) {
            width: 1px;
            height: 90%;
            left: -1rem;
            top: 5%;
          }
        }

        &:first-child:before {
          display: none;
        }

        svg {
          margin-top: 0.25rem;
          fill: $neutral--300;
        }

        h4 {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          align-items: flex-start;
          color: $neutral--300;

          img {
            margin-block-start: 0.25rem;
          }
        }

        ul {
          padding-left: 1rem;
          margin-bottom: 0;
          margin-inline: 0;

          li {
            @extend p;
            color: $neutral--300;
          }
        }
      }
    }
  }
}

#acl {
  .before-after {
    &__img {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      > div {
        flex: 1 1 33%;
        aspect-ratio: 4/3;
      }
    }
  }
}

.DateSelector {
    &--basket input {
        border-radius: 3px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
        border: none;
        height: 2rem;
        width: auto;
        margin-left: 1rem;
        text-align: center;
    }

    &--gapCheck input {
        border-radius: 3px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
        border: none;
        height: 3rem;
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }
}
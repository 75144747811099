@use '../../config' as *;

#acl {
  .features {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    align-items: flex-start;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    &__ul {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 1 1 50%;
    }

    &__thumbnail {
      border-radius: $radius;
      overflow: hidden;
      flex: 1 1 50%;
    }
  }
}

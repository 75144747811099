@use '../config.scss' as *;

#acl {
  .checkout--container {
    background: $primary--50;
    padding: 1rem 1.5rem;
    border-radius: $radius;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    // Nice, subtle
    //box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    // More prominent shadow
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    // border: 1px solid black;

    &--header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .product {
    display: flex;
    flex-direction: row;
    gap: 0;
    align-items: center;

    &--name {
      flex: 1 1 auto;
    }

    &--blocked {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &--name--blocked {
      text-decoration: line-through;
    }

    &--name--blocked a {
      color: gray !important;
    }

    &--details--blocked {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex: 1 1 auto;
      gap: 0;
      align-items: center;
    }

    &--comment--blocked {
      width: 100%;
    }

    &--cost--blocked {
      color: gray;
      text-decoration: line-through;
    }

    button {
      appearance: none;
      background: transparent;
      border: 0;
      width: 30px;
      height: 30px;
      display: inline-grid;
      place-items: center;
      cursor: pointer;

      svg {
        width: 12px;
        height: 12px;

        >* {
          fill: $primary--200;
        }
      }
    }
  }

  .summary {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__line {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      text-align: right;
      align-items: center;

      .disclaimer {
        flex: 1;
      }

      .label {
        flex: 1;
        font-size: 0.7rem;
        text-transform: uppercase;
      }

      .value {
        flex: 0 0 70px;

        &.bold {
          font-weight: bold;
          color: $primary--200;
        }
      }
    }
  }
}
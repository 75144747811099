@use '../config.scss' as *;

#acl {
  .faq {
    .faq:not(.is--active) {
      .faq__title {
        border-bottom: 1px solid $primary--50;

        svg {
          transform: rotate(-45deg);
        }
      }
    }

    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-block-end: 1rem;
      cursor: pointer;

      svg {
        width: 1rem;
        height: 1rem;
        transform-origin: center;
        transform: rotate(0);
        transition: transform 0.3s ease;

        path {
          fill: $primary--200;
        }
      }
    }

    &__answer {
      background: $primary--50;
      border-radius: $radius;
      padding: 1rem;
    }
  }
}

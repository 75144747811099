@use '../config.scss' as *;

#acl {
  .wrapper:has(.button) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-radius: $radius;
    text-decoration: none;
    transition: all 0.2s ease;
    cursor: pointer;

    &.button--primary {
      background: $primary--200;
      border: 1px solid $primary--200;
      color: var(--standout);

      svg > * {
        fill: var(--standout);
      }

      &:hover {
        background: $primary--300;
        border-color: $primary--300;
      }
    }

    &.button--secondary {
      background: white;
      border: 1px solid $primary--200;
      color: $primary--200;

      svg > * {
        fill: $primary--200;
      }

      &:hover {
        border-color: $primary--300;
        background: $primary--50;
        color: $primary--300;

        svg > * {
          fill: $primary--300;
        }
      }
    }

    &.button--reversed {
      background: $primary--50;
      border: 1px solid $primary--100;
      color: $primary--200;

      svg > * {
        fill: $primary--200;
      }

      &:hover {
        border-color: $primary--200;
        background: $primary--50;
        color: $primary--300;

        svg > * {
          fill: $primary--300;
        }
      }
    }

    &:disabled {
      opacity: 0.3;
    }

    &.button--select {
      border: 1px solid $neutral--100;
      color: $neutral--200;
      background: white;

      @include breakpoint(tablet) {
        padding: 1rem 1.5rem;
      }

      &.is--active {
        border: 1px solid $primary--200;
        color: $neutral--400;
      }
    }

    &.button--term {
      @include breakpoint(smmobile) {
        padding: 0.75rem;
      }
    }

    &.button--width {
      width: 100%;

      @include breakpoint(tablet) {
        max-width: 16.563rem;
        align-self: flex-end;
      }
    }
  }

  .button--back {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0rem;
    text-decoration: none;
    transition: all 0.2s ease;

    svg > * {
      fill: $primary--200;
    }

    .button--label {
      color: $neutral--400;
    }

    &:hover {
      svg > * {
        fill: $primary--300;
      }
      .button--label {
        color: $neutral--300;
      }
    }
  }

  .button--wrap {
    display: flex;
    gap: 1rem;

    &.button--wrap-row {
      flex-direction: row;
    }

    &.button--wrap-column {
      flex-direction: column;
    }
  }
}

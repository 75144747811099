@use '../config' as *;

#acl {
  .paragraph {
    columns: 1;
    column-gap: 2rem;
    widows: 4;

    &.paragraph--col-2 {
      p {
        break-inside: avoid;
      }

      @include breakpoint(tablet) {
        columns: 2;
      }
    }

    &.paragraph--col-3 {
      p {
        break-inside: avoid;
      }

      @include breakpoint(tablet) {
        columns: 3;
      }
    }
  }

  .disclaimer {
    font-size: .8rem;
    font-weight: 500;
    color: black;
    text-align: right;
    font-style: italic;
  }
}
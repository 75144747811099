@use '../config.scss' as *;

#acl {
  .headbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $neutral--100;
    padding-block-end: 0.75rem;

    .headbar__icon {
      position: relative;
      
      .cart__icon {
        height: 50px;
        width: 50px;

      .headbar__indicator {
        position: absolute;
        right: -1px;
        top: -1px;
        font-size: 20px;
        color: $primary--200;
        line-height: 10px;
        background-color: $primary--100;
        border-radius: 50%;
        text-align: center;
        height: 10px;
        width: 10px;
      }
    }
  }
}
}
#acl {
  .spinner {
    width: 100%;
    padding-block: 5rem;
    display: grid;
    place-items: center;
    
    &::before {
      content: '';
      display: inline-block;
      width: 75px;
      height: 75px;
      animation: spin linear 1.5s 0s infinite;
      transform-origin: center;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='-58 -58 116 116' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke-linecap='round' stroke-width='15'%3E%3Cpath id='a' d='m0 35 0,14'/%3E%3Cuse transform='rotate(210)' xlink:href='%23a' stroke='%23f0f0f0'/%3E%3Cuse transform='rotate(240)' xlink:href='%23a' stroke='%23ebebeb'/%3E%3Cuse transform='rotate(270)' xlink:href='%23a' stroke='%23d3d3d3'/%3E%3Cuse transform='rotate(300)' xlink:href='%23a' stroke='%23bcbcbc'/%3E%3Cuse transform='rotate(330)' xlink:href='%23a' stroke='%23a4a4a4'/%3E%3Cuse transform='rotate(0)' xlink:href='%23a' stroke='%238d8d8d'/%3E%3Cuse transform='rotate(30)' xlink:href='%23a' stroke='%23757575'/%3E%3Cuse transform='rotate(60)' xlink:href='%23a' stroke='%235e5e5e'/%3E%3Cuse transform='rotate(90)' xlink:href='%23a' stroke='%23464646'/%3E%3Cuse transform='rotate(120)' xlink:href='%23a' stroke='%232f2f2f'/%3E%3Cuse transform='rotate(150)' xlink:href='%23a' stroke='%23171717'/%3E%3Cuse transform='rotate(180)' xlink:href='%23a' stroke='%23000'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
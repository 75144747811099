@use '../config' as *;

#acl {
  ul.ulticklist {
    margin-block: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    height: 100%;

    > li {
      display: flex;
      flex-direction: row;
      align-items: top;
      gap: 0.5rem;
      line-height: $tight;
      text-transform: capitalize;

      svg {
        flex: 0 0 14px;
        margin-top: 5px;

        path {
          fill: $primary--200;
        }
      }

      &.ulticklist--icon-light {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
}

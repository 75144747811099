// Breakpoints
@mixin breakpoint($size) {
  @if $size == smmobile {
    @media (max-width: 400px) {
      @content;
    }
  }
  @if $size == mobile {
    @media (max-width: 599px) {
      @content;
    }
  }
  @if $size == phablet {
    @media (min-width: 600px) {
      @content;
    }
  }
  @if $size == tablet {
    @media (min-width: 800px) {
      @content;
    }
  }
  @if $size == laptop {
    @media (min-width: 1366px) {
      @content;
    }
  }
  @if $size == desktop {
    @media (min-width: 1920px) {
      @content;
    }
  }

  @if $size == iPhone {
    @media (min-width: 375px) and (orientation: portrait) {
      @content;
    }
  }
  @if $size == iPhone-landscape {
    @media (min-width: 667px) and (orientation: landscape) {
      @content;
    }
  }

  @if $size == iPhoneMax {
    @media (min-width: 414px) and (orientation: portrait) {
      @content;
    }
  }
  @if $size == iPhoneMax-landscape {
    @media (min-width: 736px) and (orientation: landscape) {
      @content;
    }
  }

  @if $size == iPad {
    @media (min-width: 1024px) and (orientation: landscape) {
      @content;
    }
  }
  @if $size == iPad-portrait {
    @media (min-width: 768px) and (orientation: portrait) {
      @content;
    }
  }

  @if $size == iPadPro {
    @media (min-width: 1112px) and (max-width: 1366px) and (orientation: landscape) {
      @content;
    }
  }
  @if $size == iPadPro-portrait {
    @media (min-width: 834px) and (max-width: 1024px) and (orientation: portrait) {
      @content;
    }
  }
}

// Brand
$primary--50: hsl(var(--hue), var(--saturation), 98%);
$primary--100: hsl(var(--hue), var(--saturation), 93%);
$primary--200: hsl(var(--hue), var(--saturation), var(--lightness));
$primary--300: hsl(var(--hue), var(--saturation), 33%);

// Neutrals
$neutral--50: hsl(var(--hue), 10%, 98%);
$neutral--100: hsl(var(--hue), 10%, 88%);
$neutral--200: hsl(var(--hue), 10%, var(--lightness));
$neutral--300: hsl(var(--hue), 10%, 33%);
$neutral--400: hsl(var(--hue), 36%, 8%);

// Success
$success--100: hsl(100, 70%, 93%);
$success--200: hsl(100, 70%, 73%);
$success--300: hsl(100, 70%, 53%);

// Warning
$warning--100: hsl(38, 81%, 93%);
$warning--200: hsl(38, 81%, 78%);
$warning--300: hsl(38, 81%, 63%);

// Error
$error--100: hsl(0, 76%, 93%);
$error--200: hsl(0, 76%, 73%);
$error--300: hsl(0, 76%, 53%);

// Box Model
$radius: 0.5rem;

// Typography

// WEIGHT
$normal: 400;
$semi: 500;
$bold: 700;

// SIZE
// Calculator https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/#for-those-who-dont-mind-that-edge-case
// Sizes based on 320px – 1172px viewports
$base: 16px;
$size--1: 0.75rem; // Label (12px)
$size--2: 0.875rem; // Caption (16px)
$size--3: clamp(1rem, 0.9531rem + 0.2347vw, 1.125rem); // P
$size--4: clamp(1.25rem, 1.1561rem + 0.4695vw, 1.5rem); // H5
$size--5: clamp(1.5rem, 1.3592rem + 0.7042vw, 1.875rem); // H4
$size--6: clamp(1.875rem, 1.7342rem + 0.7042vw, 2.25rem); // H3
$size--7: clamp(2.25rem, 1.9683rem + 1.4085vw, 3rem); // H2
$size--8: clamp(3rem, 2.7183rem + 1.4085vw, 3.75rem); // H1

// LEADING
$loose: 1.7;
$tight: 1.3;
$flat: 1.1;

// Max paragraph width
$paragraph-width: 70ch;

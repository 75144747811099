@use '../config.scss' as *;

#acl {
  .estimate-info {
    display: flex;
    gap: 2rem;
    // display: grid;
    // grid-template-columns: 100%;

    > * {
      flex: 1 1 100%;
    }

    @include breakpoint(tablet) {
      // grid-template-columns: 1fr 1px 1fr;
      // gap: 2rem;
    }
  }
  .estimate {
    h3 {
      color: $neutral--400;
    }

    &__divider {
      border: 1px solid $neutral--100;
      width: 100%;
      margin-block: 2rem !important;
      flex: 0 0 1px;

      @include breakpoint(tablet) {
        width: 80%;
      }
    }

    &__value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }

    .wrapper:has(.button--select) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

.editFinancingLoanStartDate {
    display: flex;
    flex-direction: column;

    & input {
        height: 2.5rem;
        font-size: 1.25rem;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
        border: none;
        border-radius: 5px;
        text-align: center;
    }

    & label {
        padding-bottom: 5px;
        font-size: 1.5rem;
    }
}
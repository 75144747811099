@use '../../config.scss' as *;

$gap: 1.5rem;

#acl {
  .wrapper:has(.card.card--product) {
    display: grid;
    grid-template-columns: 1fr;
    gap: $gap;

    @include breakpoint(tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .card {
    position: relative;
    color: #ffffff !important;
    border-radius: 0.5rem;
    overflow: hidden;

    @include breakpoint(tablet) {
      grid-column-end: span 2;

      &:nth-child(odd):last-child {
        grid-column-start: 2;
      }
    }

    .h2 {
      color: #fff !important;
    }

    &__overlay {
      background: linear-gradient(135deg, #171717 0%, rgba(23, 23, 23, 0) 100%);
      background-blend-mode: multiply, normal;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 1;
    }

    &__thumbnail {
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0rem;
      left: 0rem;
      z-index: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__data {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: $gap;
      padding: $gap;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}